
class LightSlider {
    static productDetail() {
        const slider = $('#lightSlider').lightSlider({
            gallery: true,
            item: 1,
            loop: true,
            slideMargin: 0,
            thumbItem: 4,
            thumbMargin: 7,
            controls: false,
        });
        $('.mi-lightslider-product__next').on('click', function () {
            slider.goToNextSlide();
        });
    }
    static productDetailSm() {
        const slider = $('#lightSlider').lightSlider({
            gallery: true,
            item: 1,
            loop: true,
            slideMargin: 0,
            thumbItem: 3,
            thumbMargin: 7,
            controls: false,
        });
        $('.mi-lightslider-product__next').on('click', function () {
            slider.goToNextSlide();
        });
    }
}

$(document).ready(function(){
    if (window.matchMedia('(max-width: 1370px)').matches) {
        LightSlider.productDetailSm();
    } else {
        LightSlider.productDetail();
    }
});
