
const cookiesToggler = () => {
    const cookiesBar = document.getElementById('cookiesBar'),
        cookiesBarBtn = document.getElementById('cookiesBarBtn');

    cookiesBarBtn.addEventListener('click', function(e) {
        e.preventDefault();
        cookiesBar.classList.toggle('cookies--approve');
    });
};

const select2init = () => {
    $(document).ready(function() {
        const categoryDetailSelector = $('.mi-cd-selector select');
        categoryDetailSelector.select2();
    });
};


$( document ).ready(function() {
    cookiesToggler();
    select2init();
    objectFitImages();
});
