class StickyNavbar {

    static init() {
        window.onscroll = function() {myFunction();};
        const navbar = document.getElementById('stickyNavbar');
        const sticky = navbar.offsetTop - 55; // minus topbar

        function myFunction() {
            if (window.pageYOffset >= sticky) {
                navbar.classList.add('sticky');
            } else {
                navbar.classList.remove('sticky');
            }
        }
    }
}

class MobileNavbar {

    static init() {
        $( '.navbar__dropdown span' ).click( function(e) {
            //e.preventDefault();
            $( this ).closest('.navbar__dropdown').siblings('.navbar__dropdown').find('> a').removeClass('active');
            $( this ).siblings('a').toggleClass('active');
        });
    }
}

$(document).ready(function () {
    StickyNavbar.init();


    if ( window.matchMedia( '(max-width: 991px)' ).matches ) {
        MobileNavbar.init();
    }
});