
class Accordions {

    static init() {
        const acc = document.getElementsByClassName('faq__btn');
        let i;

        if (acc) {
            for (i = 0; i < acc.length; i++) {
                acc[i].addEventListener('click', function() {
                    const panel = this.nextElementSibling;
                    $('.faq__btn.active + .faq__content').attr('style','');
                    $('.faq__btn').siblings().removeClass('active');
                    if ($(this).hasClass('active')) {
                        $(this).removeClass('active');
                        panel.style.maxHeight = null;
                    } else {
                        $(this).addClass('active');
                        panel.style.maxHeight = panel.scrollHeight + 'px';
                    }
                });
            }
        }
    }
}

$( document ).ready(function() {
    Accordions.init();
});

/*
<div class="faq">
<button class="faq__btn">
<span>Lorem ipsum dolor sit amet?</span>
</button>
<div class="k-faq__content">
<p></p>
</div>
</div>
 */
